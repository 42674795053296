<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <!-- <v-toolbar flat color="white" dense>
            <v-toolbar-title>Role Links</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar> -->
        <v-col md="12" sm="6">
            <v-card>
                <v-card-title>Add Role Link</v-card-title>
                
                <v-container fluid>
                    <v-row>
                        <!-- //api-2 -->
                        <v-col cols="12" sm="4"> </v-col>
                        <v-col cols="12" sm="4">
                            <v-select :items="tclist" label="Tenant" v-model="selected_tenant" :loading="fetch_org_load" @change="fetchorganization()"></v-select>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container fluid style="display: none;" id="orgdiv">
                    <v-row>
                        <v-col cols="12" sm="4"> </v-col>
                        <v-col cols="12" sm="4">
                            <v-select :items="orglist" label="TPO" v-model="selected_org" @change="fetchAllLinks()"></v-select>
                        </v-col>
                    </v-row>
                </v-container>
                

                <v-container fluid style="display: none;" id="linkdiv">
                    <v-dialog v-model="dialog1" max-width="600px">
                        <template v-slot:activator="{ on }">
                            <center><v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="iseditable=!iseditable">New Role Links</v-btn></center>
                        </template>
                        <v-card>
                             <v-form v-model="valid">
                            <v-card-title>
                                <span class="headline">Add New Role Link</span>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-select :items="link_list" item-text="linkname" label="Links" v-model="selected_link" @change="getLink()"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="sort_order"  label="Sort Order" :rules="[v => !!v || 'required']"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="selected_link_id" readonly label="LinkID"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="selected_link_link" readonly label="Link"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="selected_link_icon" readonly label="Icon"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="selected_link_usertype" readonly label="User Type"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-select :items="role_list" item-text="name" label="Role" v-model="role" :rules="[v => !!v || 'required']"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                   
                                    <v-btn color="blue darken-1" outlined  @click="dialog1 = false" style="margin: 8px;">
                                        Close
                                    </v-btn>
                                     <v-btn color="primary darken-1" dark  @click="save()" style="margin: 8px;" :disabled="!valid">save</v-btn>
                                </v-row>
                            </v-card-text>
                             </v-form>
                        </v-card>
                    </v-dialog>
                </v-container>
            </v-card>
            <br />
        </v-col>
        <v-app id="">
         <v-data-table
      :headers="headers"
      :items="present_role_links"
      class="elevation-1"
      :search="search"
    ></v-data-table>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            search: "",
            tclist: "",
            message: "",
            loading: true,
            selected_tenant: "",
            orglist: "",
            selected_org: "",
            valid: false,
            link_list: "",
            selected_link: "",
            fetch_org_load: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            dialog1: false,
            selected_link_id: "",
            selected_link_linkname: "",
            selected_link_link: "",
            selected_link_icon: "",
            selected_link_usertype: "",
            rolelink_list: null,
            role_list: "",
            iseditable: false,
            role: "",
            sort_order: '',
            present_role_links: "",
            headers: [
                {
                    text: "Link ID",
                    value: "linkid",
                },
                {
                    text: "Link Name",
                    value: "linkname",
                },
                 {
                    text: "Sort Order",
                    value: "sort_order",
                },
               
                {
                    text: "Link",
                    value: "link",
                },
                {
                    text: "Icon",
                    value: "icon",
                },
                {
                    text: "User Type",
                    value: "usertype",
                },
                {
                    text: "Role",
                    value: "role",
                },
            ],
        }),

        mounted() {
           axios
                .post("/Admin/startCopy") //api-1
                .then((res) => {
                    // window.console.log(res);
                    if (res.data.msg == "200") {
                        this.tclist = res.data.tenants;
                    } else {
                        this.message = res.data.msg;
                    }
                })
                .catch((error) => {
                    window.console.log("error:" + error);
                });
        },

        methods: {
                fetchorganization() {
                this.fetch_org_load = true;
                const data = {
                    tenantid: this.selected_tenant,
                };
                axios
                    .post("/Admin/fetchTPO", data)
                    .then((res) => {
                        // window.console.log(res);
                        if (res.data.msg == "200") {
                            this.orglist = res.data.org;
                            document.getElementById("orgdiv").style.display = "block";
                        } else {
                            this.message = res.data.msg;
                        }
                    })
                    .catch((error) => {
                        window.console.log("error:" + error);
                    })
                    .finally(() => (this.fetch_org_load = false));
            },
            fetchAllLinks() {
                //alert("in fetchalllinks");
                const data = {
                    tenantid: this.selected_tenant,
                    orgid: this.org,
                };

                axios
                    .post("/Admin/fetchAllLinks", data)
                    .then((res) => {
                        // window.console.log(res);
                        if (res.data.msg == "200") {
                            //alert("in fetchAlllink");
                            this.link_list = res.data.allLinks;
                            this.present_role_links = res.data.present_role_links;
                            //alert(1);
                            document.getElementById("linkdiv").style.display = "block";
                        } else {
                            error;
                        }
                    })
                    .catch((error) => {
                        window.console.log("error:" + error);
                    })
                    .finally(() => {});
            },
            getLink() {
                //alert("in getlink");
                const data = {
                    tenantid: this.selected_tenant,
                    orgid: this.org,
                    selectedlink: this.selected_link,
                };
                axios
                    .post("/Admin/getLink", data)
                    .then((res) => {
                        // window.console.log(res);
                        if (res.data.msg == "200") {
                            //alert("in getLink");
                            this.selected_link_id = res.data.selected_link_id;
                            this.role = res.data.role;
                            this.selected_link_link = res.data.selected_link_link;
                            this.selected_link_icon = res.data.selected_link_icon;
                           // this.rolelink_list = res.data.rolelink_list;
                            this.sort_order = res.data.sort_order;
                            this.role_list = res.data.role_list;
                            this.selected_link_usertype = res.data.selected_link_usertype;
                            //alert(1);
                            document.getElementById("getlink").style.display = "block";
                        } else {
                            error;
                        }
                    })
                    .catch((error) => {
                        window.console.log("error:" + error);
                    })
                   
            },
            close() {
                this.dialog1 = false;
            },
            save() {
                const data = {
                    tenantid: this.selected_tenant,
                    orgid: this.org,
                    selected_link: this.selected_link,
                    role: this.role,
                    usertype: this.selected_link_usertype,
                    sort_order: this.sort_order,
                };
                this.iseditable = false;
                axios
                    .post("/Admin/saveRollLink", data)
                    .then((res) => {
                        
                        if (res.data.status.code == "SUCCESS") {
                            this.present_role_links.push(this.data);
                             //alert(res.data.status.code)
                            this.showSnackbar("#4caf50", "New Role Links Added Successfully!!!"); // show snackbar on success
                        } else if (res.data.status.code == "NA") {
                            this.showSnackbar("#b71c1c", "Role Link Already Present!!!"); // show snackbar on error
                        }
                        //   this.onload()
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
                this.close();
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
        },
    };
</script>

<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
</style>
